import store from "@/store";

export default {
  namespaced: true,

  state: {
    currentLang: (localStorage.getItem("locale") as string) || "en",
    allowedLanguages: ["ru", "en"]
  },

  mutations: {
    set(state: any, lang: string) {
      state.currentLang = lang;
      localStorage.setItem("locale", lang);
    }
  },

  actions: {
    set(context: any, lang: string) {
      context.commit("set", lang);
    },
    setFromRouter(context: any) {
      const [, , , urlLang]: Array<string> = location.href.split("/");
      const allowedLang: Array<string> =
        store.getters["localization/getAllowed"];

      if (allowedLang.includes(urlLang)) {
        context.commit("set", urlLang);
      }
    }
  },

  getters: {
    getCurrent: (state: any): string => state.currentLang,
    getAllowed: (state: any): Array<string> => state.allowedLanguages
  }
};
