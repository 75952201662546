const meta = {
    module: "emails"
};

export default [
    {
        path: `/emails-history`,
        redirect: "/emails-history/page/1"
    },
    {
        path: `/emails-history`,
        redirect: "/emails-history/page/1"
    },
    {
        path: `/emails-history/page/:page`,
        name: "emails-history",
        component: () =>
            import(
                /* webpackChunkName: "emails" */ "@/modules/emails/views/index.vue"
                ),
        meta: {
            ...meta,
            module: "emails-history",
            action: "list"
        }
    }

];