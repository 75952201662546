import store from "@/store";

const userPages: any = {
  guest: "/profile/dashboard",
  manager: "/board",
  main_manager: "/board",
  admin: "/board"
};
const credentials = store.getters["authentication/credentials"];

export default [
  {
    path: `/`,
    redirect: credentials.user
      ? userPages[credentials.user.role]
      : "/auth/login"
  }
];
