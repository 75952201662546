const meta = {
  module: "profile"
};

export default [
  {
    path: `/profile/:id?/:action`,
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/modules/profile/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/profile/:id?`,
    redirect: "profile/:id?/personal"
  },
  {
    path: `/verification`,
    name: "verification",
    component: () =>
      import(
        /* webpackChunkName: "profile/verification" */ "@/modules/profile/views/verification.vue"
      ),
    meta: {
      ...meta,
      action: "upload-documents"
    }
  }
];
