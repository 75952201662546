import APIModel from "../APIModel";

export default class Documents extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "documents";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`user_documents/archive`, params);
  }

  async restore(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`user_documents/archive/${id}/restore`, {});
  }

  async generateTermsAndConditionsDocument(): Promise<any> {
    return await this.http.post(`user_documents/terms_and_conditions`, {});
  }
}
