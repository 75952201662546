
import Vue from "vue";

import i18n from "@/plugins/i18n";
import store from "@/store";

export default Vue.extend({
  name: "ConfirmDialog",

  data: () => ({
    i18n,
    showed: false as boolean,
    action: "" as string,
    title: "" as string,
    text: "" as string,
    cancelText: undefined as undefined | string,
    submitText: undefined as undefined | string,
    sheet: false as boolean,
    params: {} as any,
  }),

  computed: {
    props(): any {
      return store.getters["alert/confirmDialogProps"];
    },
  },

  watch: {
    props: {
      deep: true,
      handler() {
        if (this.props) {
          const {
            callback,
            title,
            message,
            cancelText,
            cancelAction,
            submitText,
          } = this.props;

          this.open(callback, title, message, {
            cancelText,
            cancelAction,
            submitText,
          });
        }
      },
    },
  },

  methods: {
    open(action: string, title: string, text: string, params: any): void {
      this.action = action;
      this.title = title;
      this.text = text;
      this.params = params;
      this.showed = true;
    },
    close(): void {
      this.$store.dispatch("alert/setConfirmDialogProps", null);
      this.showed = false;
    },
    confirm(): void {
      this.props.callback(this.params);
      this.close();
    },
    cancel(): void {
      if (!this.params.cancelAction) {
        this.close();
      } else {
        this.params.cancelAction(this.params);
      }
    },
  },
});
