import PusherClient, { Pusher } from "pusher-js";

export interface PusherInterface {
  create(): void;

  instance(): Pusher;
}

class PusherInstance implements PusherInterface {
  private pusher = null as any;
  private app = {
    id: "msg_power_dev",
    key: "ABCDEFG",
    secret: "HIJKLMNOP",
    name: "API",
    host: "crm-api.scoreboostsolution.com",
    path: "/wss",
    capacity: null,
    clientMessagesEnabled: false,
    statisticsEnabled: true
  };
  private port = 443;
  private authEndpoint = "http://192.168.100.32/laravel-websockets/auth";

  public create() {
    return new Promise((resolve, reject) => {
      if (this.pusher) {
        resolve();
        return;
      }

      this.pusher = new PusherClient(this.app.key, {
        wsHost: this.app.host,
        wsPort: this.port,
        wssPort: this.port,
        wsPath: this.app.path,
        disableStats: true,
        authEndpoint: this.authEndpoint,
        auth: {
          headers: {
            "X-CSRF-Token": "bvbHz0SZACoiTDyYJodJOhm9lPkF4Y8qCTvr7rPb",
            "X-App-ID": this.app.id
          }
        },
        enabledTransports: ["ws", "wss"]
      });

      this.pusher.connection.bind("connected", () => {
        resolve();
      });

      this.pusher.connection.bind("error", () => {
        resolve();
        this.pusher = null;
      });
    });
  }

  public instance(): Pusher {
    return this.pusher;
  }
}

export default new PusherInstance();
