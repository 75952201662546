import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Api from "@/api/API";
import confirm from "@/directives/confirm";
import clipboard from "@/directives/clipboard";
import i18n from "./plugins/i18n";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./plugins/ability";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import VueApexCharts from "vue-apexcharts";
import Pusher from "./services/pusher";
import fullscreen from "@/directives/fullscreen";
import moment from "moment";
import handleUnsaved from "@/directives/handle-unsaved";
// @ts-ignore
import Vidle from "v-idle";

Vue.config.productionTip = false;

Vue.component("apexchart", VueApexCharts);

Vue.prototype.$moment = moment;
Vue.prototype.$API = Api;
Vue.prototype.$Pusher = Pusher;

const options = {
  // pk: process.env.VUE_APP_STRIPE_KEY
  //stripeAccount: process.env.STRIPE_ACCOUNT,
  //apiVersion: process.env.API_VERSION,
  //locale: process.env.LOCALE,
};

Vue.use(VueApexCharts);
// Vue.use(StripePlugin, options);
Vue.use(abilitiesPlugin, ability);

Vue.use(Vidle, {
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // Events to listen for activity
});

Vue.directive("confirm", confirm);
Vue.directive("clipboard", clipboard);
Vue.directive("fullscreen", fullscreen);
Vue.directive("handle-unsaved", handleUnsaved);

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

export default vue;
