const meta = {
  module: "audits"
};

export default [
  {
    path: `/audits`,
    redirect: "/audits/page/1"
  },
  {
    path: `/audits/page`,
    redirect: "/audits/page/1"
  },
  {
    path: `/audits/page/:page`,
    name: "audits",
    component: () =>
      import(
        /* webpackChunkName: "audits" */ "@/modules/audits/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
