import APIModel from "../APIModel";

export default class Authorize extends APIModel {
  constructor() {
    super();
  }

  async createSubscription(data: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .post(`/authorize/payment/`, {
        data: data
      });
  }

  async closeUserConnection(user_id: any): Promise<any> {
    return await this.http
        .ignoreErrorHandler()
        .post(`/authorize/payment/cancel-subscription`, {
          user_id: user_id
        });
  }
}
