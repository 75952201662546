import APIModel from "../APIModel";

export default class Billing extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "billing";
  }

  async getPlans(): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`/plans`, {});
  }

  async getPlansList(): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`/plans/list`, {});
  }

  async changePlans(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`/plans/swap`, payload);
  }

  async changeCard(user: number, id: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`profiles/payment-methods/${user}/default`, { id });
  }

  async plansHistory(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`/plans/history/${user}`, {});
  }

  async getStripeInfo(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`profiles/intent/${user}`, {});
  }

  async getCardInfo(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`profiles/payment-methods/${user}/default`, {});
  }

  async getAllCards(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`profiles/payment-methods/${user}`, {});
  }

  async getRecentSubscription(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`/plans/recente_subscription`, {});
  }
}
