import APIModel from "../APIModel";

export default class Audits extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "audits";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }
}
