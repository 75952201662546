import Vue, { VNode } from "vue";
import Confirm from "@/components/dialogs/ConfirmDialog.vue";
import { DirectiveBinding } from "vue/types/options";
import store from "@/store";

const ConfirmDialog = Vue.extend(Confirm);
let element: any = null;

export default {
  bind(el: any, binding: DirectiveBinding) {
    el.style.cursor = "pointer";

    el.addEventListener("click", () => {
      setTimeout(async () => {
        await store.dispatch("alert/setConfirmDialogProps", {
          ...binding.value
        });
      }, 0);
    });

    element = el;
  }
};
