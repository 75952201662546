import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import ability from "@/plugins/ability";
import API from "@/api/API";
import Pusher from "@/services/pusher";

const loadUserInfo = async () => {
  try {
    const credentials = await store.getters["authentication/credentials"];
    const response = await API.user().checkStatus(credentials.user.id);

    await store.dispatch("user/set", response);
    await Pusher.create();

    setTimeout(async () => {
      await loadUserInfo();
    }, 60000);
  } catch (e) {
    await store.dispatch("alert/showError", e.message);
  }
};

const loadUserPermissions = async (to: Route) => {
  const permissions = [];
  const { user } = store.getters["authentication/credentials"];

  if (!user) {
    return;
  }

  try {
    const allModulePermissions = await import(
    // @ts-ignore
      `@/modules/${to.meta.module}/config/permissions.ts`
    );
    const modulePermissions = allModulePermissions.default[user.position];

    if (modulePermissions) {
      permissions.push(...modulePermissions);
    }
  } catch (e) {
    await Promise.resolve();
  } finally {
    const globalPermissions = require.context(
      "@/permissions",
      true,
      /[A-Za-z0-9-_,\s]+\.ts$/i
    );

    for (const path of globalPermissions.keys()) {
      const allGlobalPermissions = await import(
        `@/permissions/${path.substring(2)}`
      );
      const globalPermissions = allGlobalPermissions.default[user.position];

      if (globalPermissions) {
        permissions.push(...globalPermissions);
      }
    }

    ability.update(permissions);
  }
};

const apply = (router: VueRouter) => {
  router.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) => {
      const isAuthorized = store.getters["authentication/hasAccessToken"];
      const user = store.getters["user/info"];

      await store.dispatch("authentication/checkAppVersion");
      await store.dispatch("navigation/setModule", to.name);

      // @ts-ignore
      if (!to.meta.withoutCredentials && !isAuthorized) {
        next(`/auth/login`);
        return;
      }

      if (isAuthorized && !user) {
        await loadUserInfo();
      }

      await loadUserPermissions(to);

      if (to.redirectedFrom) {
        const [, firstPart] = to.redirectedFrom.split("/");

        if (["en", "ro", "ru"].includes(firstPart)) {
          next(
            `/${to.redirectedFrom
              .split("/")
              .slice(2)
              .join("/")}`
          );
          return;
        }
      }

      next();
    }
  );

  router.afterEach((to: Route, from: Route) => {
    store.dispatch("preloader/hideGlobal");
    // @ts-ignore

    if (to.meta.errorPage) {
      history.replaceState(
        {},
        document.title,
        `/${store.getters["localization/getCurrent"]}${to.redirectedFrom ||
          from.path}`
      );
    }
  });
};

export default {
  apply
};
