import Auth from "@/api/system/Auth";
import Users from "@/api/system/Users";
import Roles from "@/api/system/Roles";
import Board from "@/api/system/Board";
import Lead from "@/api/system/Lead";
import Archive from "@/api/system/Archive";
import Documents from "@/api/system/Documents";
import Stripe from "@/api/system/Stripe";
import Billing from "@/api/system/Billing";
import Manager from "@/api/system/Manager";
import Notification from "@/api/system/Notification";
import Messenger from "@/api/system/Messenger";
import Location from "@/api/system/Location";
import Audits from "@/api/system/Audits";
import Clients from "@/api/system/Clients";
import Authorize from "@/api/system/Authorize";

export default {
  auth: () => new Auth(),
  user: () => new Users(),
  notification: () => new Notification(),
  board: () => new Board(),
  billing: () => new Billing(),
  lead: () => new Lead(),
  archive: () => new Archive(),
  documents: () => new Documents(),
  manager: () => new Manager(),
  location: () => new Location(),
  messenger: () => new Messenger(),
  roles: () => new Roles(),
  stripe: () => new Stripe(),
  client: () => new Clients(),
  audits: () => new Audits(),
  authorize: () => new Authorize()
};
