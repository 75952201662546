import ApiModel from "../APIModel";

export default class User extends ApiModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "users";
  }

  async getNewTasksCount(id: number): Promise<any>{
    return await this.http.get(`/tasks/count/${id}`);
  }

  /**
   * Delete a task
   */
  async deleteTask(params: object): Promise<any>{
    return await this.http.delete(`/tasks/`, params);
  }

  /**
   * Update a task
   */
  async updateTask(id: any, params: object): Promise<any>{
    return await this.http.put(`/tasks/${id}`, params);
  }

  /**
   * Get all tasks
   */
  async getTasks(params: object): Promise<any>{
    return await this.http.get('/tasks/', params);
  }

  /**
   * Store Task
   */
  async storeTask(params: Object): Promise <any> {
    return await this.http.post('/tasks/', params);
  }

  /**
   * Delete user credit score history item
   * @param id
   */
  async deleteCreditScoreHistoryItem(id: number): Promise <any>{
    return await this.http.delete(`profiles/credit-score/${id}`);
  }

  /**
   * Delete a recommendation by recommendation id
   * @param id
   */
  async deleteRecommendation(id: number): Promise <any> {
    return await this.http.delete(`profiles/recommendation/${id}`);
  }

  /**
   * Store or update the recommendation
   * @param id
   * @param payload
   */
  async storeRecommendation(id: number, payload: Object): Promise <any>{
    return await this.http.post(`profiles/${id}/recommendation`, payload);
  }

  // Get user recommendations
  async getRecommendations(id: number): Promise <any> {
    return await this.http.get(`profiles/${id}/recommendations`)
  }
  // Store dispute status
  async storeDisputeStatus(id: number, payload: Object): Promise <any>{
    return await this.http.post(`profiles/${id}/dispute-status`, payload);
  }

  // Get the dispute status
  async getDisputeStatus(id: number): Promise <any> {
    return await this.http.get(`profiles/${id}/dispute-status`)
  }

  // Get credit score
  async getCreditHistory(id: number): Promise <any> {
    return await this.http.get(`profiles/${id}/credit-history`);
  }

  // Get the latest credit score
  async getTheLatestCreditScore(id: number): Promise <any> {
    return await this.http.get(`profiles/${id}/credit-score`);
  }

  // Update the credit score for specific user
  async updateCreditScore(id: number, payload: Object): Promise<any>{
    return await this.http.post(`profiles/${id}/credit-score`, payload);
  }

  async checkStatus(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/read`, {});
  }

  async deleteFeedback(payload: object): Promise<any> {
    return await this.http.delete(`users_feedback`, payload);
  }

  async getOnline(): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/get_online`, {});
  }

  async getFeedbackList(params: any): Promise<any> {
    return await this.http.ignoreErrorHandler().get(`users_feedback`, params);
  }

  async getLogs(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/activities`, params);
  }

  async profile(userId?: number): Promise<any> {
    if (userId) {
      return await this.http.get(`profiles/${userId}`);
    }
    return await this.http.get(`profiles`);
  }

  async chekResetToken(token: string): Promise<any> {
    return await this.http.get(`auth/check-reset-token/${token}`);
  }

  async uploadDocuments(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`profiles/verification`, payload);
  }

  async sendFeedback(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`users_feedback`, payload);
  }

  async uploadUserDocuments(payload: object, userId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`user_documents/${userId ? userId : ""}`, payload);
  }

  async showDocuments(userId: number): Promise<any> {
    return await this.http.get(`user_documents/${userId}`);
  }

  async confirmUser(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`profiles/${user}/confirm`, {});
  }

  async rejectUser(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`profiles/${user}/reject`, {});
  }

  async changePersonalData(userId: number, user: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`profiles/personal/${userId}?_method=PATCH`, user);
  }

  async changePassword(user: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`update_password`, user);
  }

  async changeAvatar(userId: number, avatar: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${userId}/avatars`, avatar);
  }

  async deleteDocument(documentId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`user_documents/${documentId}`, {});
  }

  async deleteAvatar(userId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`${this.resourceUrl}/${userId}/avatars`, {});
  }

  async getUsers(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async getStatuses(): Promise<any> {
    return await this.http.ignoreErrorHandler().get(`statuses/user`, {});
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .delete(`${this.resourceUrl}`, { id });
  }

  async changeUserPassword(id: any, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/change_password`, { payload });
  }
}
