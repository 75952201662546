import APIModel from "../APIModel";

export default class Manager extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "managers";
  }

  async checkStatus(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/read`, {});
  }

  async deleteFeedback(payload: object): Promise<any> {
    return await this.http.delete(`users_feedback`, payload);
  }

  async getOnline(): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/get_online`, {});
  }

  async getFeedbackList(params: any): Promise<any> {
    return await this.http.ignoreErrorHandler().get(`users_feedback`, params);
  }

  async getLogs(): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/activities`, {});
  }

  async profile(userId?: number): Promise<any> {
    if (userId) {
      return await this.http.get(`profiles/${userId}`);
    }
    return await this.http.get(`profiles`);
  }

  async chekResetToken(token: string): Promise<any> {
    return await this.http.get(`auth/check-reset-token/${token}`);
  }

  async uploadDocuments(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`profiles/verification`, payload);
  }

  async sendFeedback(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`users_feedback`, payload);
  }

  async uploadUserDocuments(payload: object, userId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`user_documents/${userId ? userId : ""}`, payload);
  }

  async showDocuments(userId: number): Promise<any> {
    return await this.http.get(`user_documents/${userId}`);
  }

  async confirmUser(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`profiles/${user}/confirm`, {});
  }

  async rejectUser(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`profiles/${user}/reject`, {});
  }

  async changePersonalData(userId: number, user: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`profiles/personal/${userId}?_method=PATCH`, user);
  }

  async changePassword(user: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`update_password`, user);
  }

  async changeAvatar(userId: number, avatar: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${userId}/avatars`, avatar);
  }

  async deleteDocument(documentId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`user_documents/${documentId}`, {});
  }

  async deleteAvatar(userId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`${this.resourceUrl}/${userId}/avatars`, {});
  }

  async getUsers(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async changeUserPassword(id: any, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/change_password`, { payload });
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getList(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, {});
  }

  async getStatuses(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${id}/statuses`, {});
  }

  async edit(id: number, data: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${id}`, data);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async getActiveUsers(id: number, actives?: boolean): Promise<any> {
    const params = actives
      ? {
          filter: {
            subscription_status: "active"
          }
        }
      : {};
    return await this.http
      .ignoreErrorHandler(500)
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/${id}/clients`, params);
  }
}
