import APIModel from "../APIModel";

export default class Location extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "managers";
  }

  async getStates(): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`states/list`, {});
  }
}
