import ApiModel from "../APIModel";

export default class User extends ApiModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "clients";
  }

  async checkStatus(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/read`, {});
  }

  async deleteFeedback(payload: object): Promise<any> {
    return await this.http.delete(`users_feedback`, payload);
  }

  async getOnline(): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/get_online`, {});
  }

  async getFeedbackList(params: any): Promise<any> {
    return await this.http.ignoreErrorHandler().get(`users_feedback`, params);
  }

  async getLogs(): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/activities`, {});
  }

  async profile(userId?: number): Promise<any> {
    if (userId) {
      return await this.http.get(`profiles/${userId}`);
    }
    return await this.http.get(`profiles`);
  }

  async chekResetToken(token: string): Promise<any> {
    return await this.http.get(`auth/check-reset-token/${token}`);
  }

  async uploadDocuments(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`profiles/verification`, payload);
  }

  async sendFeedback(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`users_feedback`, payload);
  }

  async uploadUserDocuments(payload: object, userId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`user_documents/${userId ? userId : ""}`, payload);
  }

  async showDocuments(userId: number): Promise<any> {
    return await this.http.get(`user_documents/${userId}`);
  }

  async confirmUser(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`profiles/${user}/confirm`, {});
  }

  async rejectUser(user: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`profiles/${user}/reject`, {});
  }

  async changePersonalData(userId: number, user: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`profiles/personal/${userId}?_method=PATCH`, user);
  }

  async changePassword(user: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`update_password`, user);
  }

  async changeAvatar(userId: number, avatar: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${userId}/avatars`, avatar);
  }

  async getData(): Promise<Array<any>> {
    return new Promise(resolve => {
      resolve(
        [...new Array(6)].map((item, index) => ({
          id: index + 1,
          full_name: "John Underboot",
          username: "admin@gmail.com",
          password: "***********",
          roles: [
            { text: "Admin", value: 1, color: "green" },
            { text: "Test", value: 2, color: "#cccccc" }
          ]
        }))
      );
    });
  }

  async deleteDocument(documentId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`user_documents/${documentId}`, {});
  }

  async deleteAvatar(userId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`${this.resourceUrl}/${userId}/avatars`, {});
  }

  async getUsers(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async getStatuses(): Promise<any> {
    return await this.http.ignoreErrorHandler().get(`statuses/user`, {});
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .delete(`${this.resourceUrl}`, { id });
  }

  async changeUserPassword(id: any, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/change_password`, { payload });
  }
}
