const meta = {
  module: "billing"
};

export default [
  {
    path: `/billing/select-plan`,
    name: "billing/select-plan",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "@/modules/billing/views/select-plan.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/billing/successful-payment`,
    name: "billing/successful-payment",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "@/modules/billing/views/stripe-pay-success.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/billing/plan/:id`,
    name: "payment",
    component: () =>
        import(
            /* webpackChunkName: "billing" */ "@/modules/billing/views/payment-page.vue"
            ),
    meta: {
      ...meta
    }
  }
];
