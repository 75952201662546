import APIModel from "../APIModel";

export default class Stripe extends APIModel {
  constructor() {
    super();
  }

  async selectSubscriptionPlan(plan: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .post(`/stripe/select-subscription-plan`, {
        plan: plan
      });
  }
  async closeUserConnection(user_id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .post(`/stripe/cancel-subscription`, {
        user_id: user_id
      });
  }
}
