import APIModel from "../APIModel";

export default class Lead extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "leads";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async search(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/search`, params);
  }

  async get(id: number): Promise<any> {
    // return new Promise(resolve => {
    //   resolve({
    //     id: id,
    //     name: "Bumbac Vasile Petru",
    //     source: "facebook",
    //     board_status_id: 1,
    //     manager: {
    //       id: 1,
    //       name: "Main Manager"
    //     },
    //     has_duplicate: true,
    //     contacts: {
    //       emails: ["testtest@test.ru", "testtest@test.en", "testtest@test.uk"],
    //       phones: ["+3736106278543", "+3736106267843", "+3736106287853"]
    //     },
    //     created_at: "01.02.2021",
    //     updated_at: "01.04.2021"
    //   });
    // });
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${id}/show`, {});
  }

  async getList(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, {});
  }

  async extendSubscription(id: number, params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`${this.resourceUrl}/${id}/extend_subscription`, params);
  }

  async getEmails(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${id}/mail`, {});
  }

  async sendEmail(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .useFormData()
      .post(`${this.resourceUrl}/${id}/mail`, payload);
  }

  async edit(id: number, data: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${id}`, data);
  }

  async confirmEmail(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`users/${id}/activate`, {});
  }

  async changeManger(lead: number, manager: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${lead}/assign/${manager}`, {});
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async move(id: any, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${id}/move`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .delete(`${this.resourceUrl}`, { id });
  }
}
