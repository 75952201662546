const meta = {
  module: "documents"
};

export default [
  {
    path: `/documents`,
    redirect: "/documents/page/1"
  },
  {
    path: `/documents/page`,
    redirect: "/documents/page/1"
  },
  {
    path: `/documents/page/:page`,
    name: "documents",
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/modules/documents/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
