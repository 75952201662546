export default {
  namespaced: true,

  state: {
    roomId: ""
  },

  mutations: {
    set(state: any, value: boolean) {
      state.roomId = value;
    },
    remove(state: any) {
      state.roomId = "";
    }
  },

  actions: {
    set(context: any, value: boolean) {
      context.commit("set", value);
    },
    remove(context: any) {
      context.commit("remove");
    }
  },

  getters: {
    roomId: (state: any): boolean => state.roomId
  }
};
