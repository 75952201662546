const meta = {
  module: "errors",
  errorPage: true
};

export default [
  {
    path: `*`,
    redirect: `/error/404`
  },
  {
    path: `/error/404`,
    name: "error/404",
    component: () =>
      import(
        /* webpackChunkName: "error404" */ "@/modules/errors/views/404.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/error/403`,
    name: "error/403",
    component: () =>
      import(
        /* webpackChunkName: "error403" */ "@/modules/errors/views/403.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/error/500`,
    name: "error/500",
    component: () =>
      import(
        /* webpackChunkName: "error500" */ "@/modules/errors/views/500.vue"
      ),
    meta: {
      ...meta
    }
  }
];
