import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import middleware from "./middleware";

import authentication from "@/router/modules/authentication";
import errors from "@/router/modules/errors";
import general from "@/router/modules/general";
import roles from "@/router/modules/roles";
import users from "@/router/modules/users";
import archive from "@/router/modules/archive";
import documents from "@/router/modules/documents";
import board from "@/router/modules/board";
import profile from "@/router/modules/profile";
import billing from "@/router/modules/billing";
import audits from "@/router/modules/audits";
import emails from "@/router/modules/emails";
import managers from "@/router/modules/managers";
import clients from "@/router/modules/clients";
import tasks from "@/router/modules/tasks";
import store from "@/store";

const routes: Array<RouteConfig> = [
  ...errors,
  ...general,
  ...authentication,
  ...roles,
  ...billing,
  ...archive,
  ...documents,
  ...profile,
  ...board,
  ...users,
  ...managers,
  ...clients,
  ...audits,
  ...emails,
  ...tasks
];

Vue.use(VueRouter);

store.dispatch("localization/setFromRouter");

const router = new VueRouter({
  mode: "history",
  base: store.getters["localization/getCurrent"],
  routes
});

middleware.apply(router);

export default router;
