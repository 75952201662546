import ApiModel from "../APIModel";

export default class Notification extends ApiModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "notifications";
  }

  async get(id: number, payload: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/list`, payload);
  }
}
