export default {
  light: {
    primary: "#41b184",
    "light-primary": "#ddf8ec",
    "icon-color": "#3c9a72",
    aliceblue: "#FE4C43",
    aquamarine: "#1099D7",
    "background-color": "#f6f5f5",
    bisque: "#FCA81A",
    antiquewhite: "#D30980",
    secondary: "#262B33",
    "light-secondary": "#666666",
    light: "#ebeff2",
    selected: "#F5F6F8",
    "light-gray": "#81848B",
    "lighter-gray": "#ECECEC",
    text: "#444444",
    blue: "#46BCFF",
    darkBlue: "#00233C",
    purple: "#965BF7",
    error: "#F7685B",
    success: "#2ED47A",
    warning: "#FFB948",
    hover: "#393c41",
    greyish: "#eceff1",
    "odd-row": "#f9f9f9",
    /* NavBar Colors */
    "nav-bar-color": "#ffffff",
    "nav-bar-icons": "#ffffff",
    "nav-bar-background": "#F2F2F2",
    /* SideBar Colors */
    "side-bar-text-color": "#ffffff",
    "side-bar-header-color": "#666666",
    "side-bar-color": "#262B33",
    "side-bar-hovered-color": "#393c41",
    "side-bar-selected-text-color": "#F5F6F8",
    "side-bar-accent-color": "#1b1f24",
    "table-header-color": "#f6f7f7",
    "card-color": "#ffffff",
    "card-header-background": "#173042",
    "black": "#173042",
    "card-table-bg": "#F5F5F5",
    "logo-text": "#1e1e1e",
    "scroll-color": "#e0dfe1",
    "text-field-border": "#D9DFF6",
    "table-completed" : "#e1e1e1",
    "stripe-gray": "#1a1a1a",
  },
  dark: {
    primary: "#52dea5",
    secondary: "#262B33",
    "light-secondary": "#d2d2d2",
    light: "#EBEFF2",
    selected: "#F5F6F8",
    "light-gray": "#81848B",
    "lighter-gray": "#ECECEC",
    text: "#444444",
    blue: "#46BCFF",
    darkBlue: "#00233C",
    purple: "#965BF7",
    error: "#F7685B",
    success: "#2ED47A",
    warning: "#FFB948",
    hover: "#393c41",
    greyish: "#121212",
    "odd-row": "#f9f9f9",
    /* NavBar Colors */
    "nav-bar-color": "#1e1e1e",
    "nav-bar-icons": "#ffffff",
    /* SideBar Colors */
    "side-bar-text-color": "#81848B",
    "side-bar-header-color": "#666666",
    "side-bar-color": "#262B33",
    "side-bar-hovered-color": "#393c41",
    "side-bar-selected-text-color": "#F5F6F8",
    "side-bar-accent-color": "#1b1f24",
    "table-header-color": "#1e1e1e",
    "card-color": "#1e1e1e",
    "logo-text": "#ffffff",
    "scroll-color": "#484848",
    "text-field-border": "#81848B"
  }
};
