import APIModel from "../APIModel";

export default class Board extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "boards";
  }

  async get(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${id}`, {});
  }

  async edit(id: number, data: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`board_statuses/${id}`, data);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`board_statuses`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async deleteColumn(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .delete(`board_statuses`, { id });
  }
}
