import APIModel from "../APIModel";

export default class Messenger extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "messenger";
  }

  async getRoomMessages(room: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/rooms/${room}/messages`, {});
  }

  async getRoomLastMessages(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/messages/load`, payload);
  }

  async getRooms(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/rooms`, {});
  }

  async getRoom(room: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/rooms/${room}`, {});
  }

  async getContacts(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/contacts`, {});
  }

  async getRoomMembers(room: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/rooms/${room}`, {});
  }

  async addRoom(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .post(`${this.resourceUrl}/rooms`, payload);
  }

  async sendMessage(sender: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .useFormData()
      .post(`${this.resourceUrl}/members/${sender}/messages`, payload);
  }

  async readMessage(reader: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`${this.resourceUrl}/members/${reader}/messages`, payload);
  }

  async updateMessage(message: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .useFormData()
      .post(`${this.resourceUrl}/messages/${message}?_method=PATCH`, payload);
  }

  async deleteMessage(message: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .delete(`${this.resourceUrl}/messages/${message}`, {});
  }

  async addRoomMember(room: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .post(`${this.resourceUrl}/rooms/${room}/members`, payload);
  }

  async removeRoomMember(room: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .delete(`${this.resourceUrl}/rooms/${room}/members`, {});
  }

  async renameRoom(room: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`${this.resourceUrl}/rooms/${room}/rename`, payload);
  }

  async editRoom(room: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`${this.resourceUrl}/rooms/${room}`, payload);
  }

  async removeRoom(room: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .delete(`${this.resourceUrl}/rooms/${room}`, {});
  }
}
