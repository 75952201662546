const meta = {
  module: "managers"
};

export default [
  {
    path: `/${meta.module}`,
    redirect: `/${meta.module}/page/1`
  },
  {
    path: `/${meta.module}/page`,
    redirect: `/${meta.module}/page/1`
  },
  {
    path: `/${meta.module}/page/:page`,
    name: `${meta.module}`,
    component: () =>
      import(
        /* webpackChunkName: "${meta.module}" */ `@/modules/${meta.module}/views/index.vue`
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/${meta.module}/create`,
    name: `${meta.module}/create`,
    component: () =>
      import(
        /* webpackChunkName: "${meta.module/edit" */ `@/modules/${meta.module}/views/create`
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/${meta.module}/edit/:id`,
    name: `${meta.module}/edit`,
    component: () =>
      import(
        /* webpackChunkName: "${meta.module/edit" */ `@/modules/${meta.module}/views/edit`
      ),
    meta: {
      ...meta,
      action: "create"
    }
  }
];
