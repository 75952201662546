const meta = {
  module: "archive"
};

export default [
  {
    path: `/archive`,
    redirect: "/archive/page/1"
  },
  {
    path: `/archive/page`,
    redirect: "/archive/page/1"
  },
  {
    path: `/archive/page/:page`,
    name: "archive",
    component: () =>
      import(
        /* webpackChunkName: "archive" */ "@/modules/archive/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
