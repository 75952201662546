export default{
    namespaced: true,
    state: {
        uncompletedTasks: null
    },

    getters: {
        uncompletedTasks: (state: any): string => state.uncompletedTasks
    },

    actions: {
        set(context: any, value: boolean) {
            context.commit("set", value);
        },
    },

    mutations: {
        set(state: any, value: boolean) {
            state.uncompletedTasks = value;
        },
    }
}