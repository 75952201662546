import store from "@/store";

const meta = {
  module: "board"
};

export default [
  {
    path: `/board/:managerId?/:cardId?`,
    name: "board",
    component: () =>
      import(/* webpackChunkName: "board" */ "@/modules/board/views/index.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: `/board`,
    redirect: `board/${store.getters["authentication/credentials"].user?.id}`
  }
];
