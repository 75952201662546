import APIModel from "../APIModel";

export default class Archive extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "leads";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/archive`, params);
  }

  async deleteClient(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .delete(`${this.resourceUrl}/${id}`, {});
  }

  async getManagersList(): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`managers/list`, {});
  }

  async restore(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/archive/${id}/restore`, {});
  }
  async list(email: object): Promise<any> {
    return await this.http.ignoreErrorHandler().get("emails-history", email);
  }

  async getBodyFromEmail(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler().get("get-email-body", {id});
  }

  async getAllEmails(): Promise<any> {
    return await this.http.ignoreErrorHandler().get("get-emails", {});
  }
}
