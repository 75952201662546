const meta = {
    module: "tasks"
};

export default [
    {
        path: `/tasks/:client_id?`,
        name: "tasks",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/modules/tasks/views/index.vue"),
        meta: {
            ...meta
        },
        props: true,
    }
];
